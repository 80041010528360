import { clsx } from 'clsx';
import React, { memo } from 'react';

import styles from './styles.module.css';
import { generateResponsiveStyles } from './util';

import type { CarouselStyles } from './util';
import type { SlideItem } from '@/types/carousel';
import type { ReactNode } from 'react';

interface SliderSlideProps {
  children: ReactNode[];
  carousel: Map<number, SlideItem>;
  customStyles?: CarouselStyles;
  spacingOverride?: number | undefined;
}

export const SlideWrapper = memo(
  ({ children, carousel, customStyles, spacingOverride }: SliderSlideProps) => {
    const responsiveStyles = generateResponsiveStyles(
      customStyles,
      spacingOverride,
    );

    return (
      <>
        {React.Children.map(children, (child, index) => {
          if (!child) {
            return null;
          }

          return (
            <div
              data-slide={index}
              className={clsx(styles.mobileSlide, 'slider-slide')}
              ref={(node) => {
                if (node) {
                  carousel.set(index, { isVisible: undefined, element: node });
                } else {
                  carousel.delete(index);
                }
              }}
            >
              <style jsx>{responsiveStyles}</style>
              {child}
            </div>
          );
        })}
      </>
    );
  },
);

SlideWrapper.displayName = 'SlideWrapper';
